<template>
    <table class="col-12">
        <thead>
            <tr>
                <th>Наименование</th>
                <th>Статус</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <AppealTableElement 
                v-for="report in reportList"
                :key="report.id"
                :id="report.id"
                :title="report.title"
            />
        </tbody>
    </table>
</template>
<script>
    import AppealTableElement from "@/components/Reports/Appeal/Elements/AppealList/AppealTableElement.vue";

    export default {
        components: { AppealTableElement },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            reportList () {
                return this.$store.state.reports.filter((report) => report.group=='QR КП')
            }
        },
        mounted() {
            this.$store.dispatch("fetchReports");
        }
    }
</script>