<template>
  <div class="row">
    <div class="col-12 card">
      <h4>Охрана труда</h4>
      <div class="row justify-content-space-between top-24">
          <div class="col-6">
            <div class="row justify-content-space-between">
              <!-- <div class="text-field col-8">
                <div class="text-field__icon">
                  <input class="text-field__search" type="search" name="search" id="search" placeholder="Поиск по таблице" value="">
                  <span class="text-field__aicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
                  </span>
                </div>
              </div> -->
              <!-- <div class="col-4">
                <router-link to="/reports/create"><button class="btn-primary"><span class="icon-Plus" style="left:4px;"></span></button></router-link>
              </div> -->
            </div>
          </div>
      </div>
      <div class="row">

          <ProtectionTableView/>

      </div>
    </div>
  </div>
</template>

<script>
import ProtectionTableView from "@/components/Reports/LaborProtection/Elements/ProtectionList/ProtectionTableView.vue";

export default {
  components: { ProtectionTableView },
  data() {
    return {
      title: "Охрана труда",
      currentTab: 'labor'
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  // async mounted() {
  //   await this.$store.dispatch("fetchChecklists");
  // }
};
</script>
