<template>
  <div class="row justify-content-space-between" v-if="currentUser.groups.includes('Руководитель')">
    <div class="col-12">
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'labor'}" @click="currentTab = 'labor'">Охрана труда</button>
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'appeal'}" @click="currentTab = 'appeal'">Жалобы на вывоз</button>
      <!-- <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'fact'}" @click="currentTab = 'fact'">Завод</button> -->
    </div>
  </div>
  <div v-if="currentUser.groups.includes('Руководитель')">
    <ProtectionList v-if="currentTab === 'labor'"/>
    <AppealList v-if="currentTab === 'appeal'"/>
  </div>
  <div v-else>
    <ProtectionList v-if="currentUser.groups.includes('Охрана труда')"/>
    <AppealList v-if="currentUser.groups.includes('QR КП')"/>
  </div>
</template>

<script>
  import ProtectionList from "@/components/Reports/LaborProtection/ProtectionList.vue";
  import AppealList from "@/components/Reports/Appeal/AppealList.vue";

  export default {
    components: { ProtectionList, AppealList },
    data() {
      return {
        title: "Охрана труда",
        currentTab: 'labor'
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
    },
    async mounted() {
      await this.$store.dispatch("fetchChecklists");
    }
  };
</script>
