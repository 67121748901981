<template>
    <tr>
        <td><router-link :to="{path: '/reports/protection/' + id}">{{ title }}</router-link></td>
        <td>{{ questions.length }}</td>
        <td>{{ finish_checklists }}</td>
        <td><span class="icon-Check green"></span>Активный</td>
    </tr>
</template>

<script>
    export default {
        props: ['id', 'title', 'questions', 'finish_checklists'],
    }
</script>