<template>
    <table class="col-12">
        <thead>
            <tr>
                <th >Наименование</th>
                <th class="width-10">Кол-во вопросов</th>
                <th class="width-10">Кол-во отчётов сдано</th>
                <th>Статус</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ProtectionTableElement 
                v-for="report in reportList" 
                :key="report.id"
                :id = "report.id"
                :title="report.title"
                :finish_checklists="report.finish_checklists"
                :questions="report.questions"
            />
        </tbody>
    </table>
</template>
<script>
    import ProtectionTableElement from "@/components/Reports/LaborProtection/Elements/ProtectionList/ProtectionTableElement.vue";

    export default {
        components: { ProtectionTableElement },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            reportList () {
                return this.$store.state.reports.filter((report) => report.group=='Охрана труда')
            }
        },
        mounted() {
            this.$store.dispatch("fetchReports");
        }
    }
</script>